.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #121212; /* Dark background color for the spinner */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.logo {
  display: flex; /* Use flexbox to allow easy vertical centering */
  align-items: center; /* Center vertically within the Navbar */
  height: 100%; /* Set the height to fill the Navbar vertically */
}

.video-responsive {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;  /* 16:9 Aspect Ratio */
  border-radius: 15px;  /* Rounded corners */
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;  /* Optional: removes the default iframe border */
}

@font-face {
  font-family: 'Sentient-Regular';
  src: url('./assets/fonts/Sentient-Regular.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/Sentient-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Sentient-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Sentient-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/Sentient-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-Bold';
  src: url('./assets/fonts/Sentient-Bold.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/Sentient-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Sentient-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Sentient-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/Sentient-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-Light';
  src: url('./assets/fonts/Sentient-Extralight.eot'); /* IE9 Compat Modes */
  src: url('./assets/fonts/Sentient-Extralight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/Sentient-Extralight.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/Sentient-Extralight.woff') format('woff'), /* Pretty Modern Browsers */
       url('./assets/fonts/Sentient-Extralight.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}

.sentient-font{
  font-family: 'Sentient-Light', sans-serif; /* Fallback to sans-serif in case the font doesn't load */
}

.sentient-heading{
  font-family: 'Sentient-Regular', sans-serif; /* Fallback to sans-serif in case the font doesn't load */
}

.sentient-bold{
  font-family: 'Sentient-Bold', sans-serif; /* Fallback to sans-serif in case the font doesn't load */
}

.hero-title {
  font-family: 'Sentient-Regular', sans-serif; /* Fallback to sans-serif in case the font doesn't load */
  font-size: 3.25rem;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2rem; /* Smaller for tablets and large phones */
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem; /* Even smaller for small phones */
  }
}

@media (max-width: 480px) {
  .hero-title {
    line-height: 1.4;  /* Ensuring proper line spacing for smaller text */
  }
}